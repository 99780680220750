$('.module_grid .module').on('click', function(e){
    let c = $(this).attr('class').split(' ')[2]

    $('.collapsable').hide();
    $('.' + c + '_collapse').show();
    
    console.log(c);
})

$('.collapsable .vragen, .collapsable .reflectie').each(function(){
    let currentModule = $(this);
    let q_swapper = $(this).find('.q_swapper_admin');

    let vragen_count = $(currentModule).find('.vraag_field').length;

    for(let i = 0;i < vragen_count; i++)
    {
        if(i == 0)
        {   
            $(q_swapper).find('.next_admin').before('<span class="ball ball'+ (i+1) +' first activeball"><div class="balltext">' + (i+1) + '</div></span>');
        }
        else if(i == vragen_count-1)
        {
            $(q_swapper).find('.next_admin').before('<span class="ball ball'+ (i+1) +' last"><div class="balltext">' + (i+1) + '</div></span>');
        }
        else
        {
            $(q_swapper).find('.next_admin').before('<span class="ball ball'+ (i+1) +'"><div class="balltext">' + (i+1) + '</div></span>');
        } 
    }

    $(q_swapper).find('.next_admin').on('click', function(){
        let activeball =  $(currentModule).find('.activeball');
        if(activeball.next('.ball').length != 0)
        {
            $(activeball).toggleClass('activeball').next('.ball').toggleClass('activeball');
            let vraag = $(activeball).next().text();
            $(currentModule).find('.vraag').css('display', 'none');
            $(currentModule).find('.vraag' + vraag).css('display', 'block');
        }      
    })

    $(q_swapper).find('.prev_admin').on('click', function(){
        let activeball =  $(currentModule).find('.activeball');
        if(activeball.prev('.ball').length != 0)
        {
            $(activeball).toggleClass('activeball').prev('.ball').toggleClass('activeball');
            let vraag = $(activeball).prev().text();
            $(currentModule).find('.vraag').css('display', 'none');
            $(currentModule).find('.vraag' + vraag).css('display', 'block');
        }     
    })

    $('.go_to_slide3').click(function(){
        $('.slide1').hide();
        $('.slide2').hide();
        $('.slide3').show();
    })

    $('.go_to_slide2').click(function(){
        $('.slide1').hide();
        $('.slide2').show();
        $('.slide3').hide();
    })
    
    $('.go_to_slide1').click(function(){
        $('.slide2').hide();
        $('.slide1').show();
        $('.slide3').hide();
    })


})