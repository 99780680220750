
function addCalendarItem()
{
    let token = $('meta[name="csrf-token"]').attr('content');

    let D = $('#datum').val();
    let T = $('#taak').val();

    $.ajax({
        type: 'POST',
        url: 'taken/add',
        data: {"_token":token, date: D, taak: T},
        dataType: 'json',
        success: function(response){
            $('.day[data-date=' + D + ']').addClass('heeft_taak')
        },
        fail: function(e){
            console.log(e);
        },
        ajaxStop: function(){
            location.reload();
        }
      });

};


var w_location = window.location.pathname;

// if(w_location.includes('dashboard') || w_location.includes('taken') || w_location.includes('admin'))
// {
//     $(document).ready(function() {
//         var carousel = $('.main-carousel');

//         carousel.on( 'ready.flickity', function( event, index ) {
//             let taken = $('.is-selected').find('.taken')[0].innerHTML;

//             document.querySelector('.bottom_inner').innerHTML = taken;
//         });

//         carousel.on( 'change.flickity', function( event, index ) {
//             let taken = $('.is-selected').find('.taken')[0].innerHTML;
//             let date = $('.is-selected').find('.item').attr('data-date');

//             $('#datum').val(date);
//             document.querySelector('.bottom_inner').innerHTML = taken;
//         });

//         carousel.flickity({
//             cellAlign: 'center',
//             contain: true,
//             pageDots: false
//         });

//         var $carousel = $('.main-carousel').flickity({
//             initialIndex: 1
//           });
//           var flkty = $carousel.data('flickity');
          
//           $carousel.on( 'staticClick.flickity', function( event, pointer, cellElement, cellIndex ) {
//             if ( !cellElement ) {
//               return;
//             }
//             if ( cellIndex == flkty.selectedIndex ) {
//               $carousel.flickity('next');
//             } else {
//               $carousel.flickity( 'select', cellIndex );
//             }
//           });
          

//         $('.day').each(function() {
//             if ($(this).find('.taak').length > 0) {
//                 $(this).addClass('heeft_taak')
//             }
//             if ($(this).find('.verjaardag').length > 0) {
//                 $(this).addClass('heeft_verjaardag')
//             }
//         })

//         setTimeout(function() {
//             $('.day.active').click();
//         }, 500)

//     });
// }


$('.taken_pagina .day').on('click', function (e) {
    let date = $(this).attr('data-date');

    // console.log(e.target);

    $('#datum').val(date);
});

