var audio = document.querySelector("audio"),
    playButton = document.querySelector("#playButton"),
    playButtonCircle = document.querySelector('#audio-play circle')
    progress = document.querySelector(".audio.p_bar > .p_bar_fill"),
    currentTime = document.querySelector('.currentTime'),
    duration = document.querySelector('.duration');
    // volumeRange = document.querySelector("#volume-range");
 
var ct;
 
  // volumeRange.addEventListener("change",(event)=>{
  //   audio.volume = event.target.value / 100;
  // });
 
// This closing brace appears to be unnecessary and causing a syntax error.
// Removing it should resolve the issue.
 
function savePodcastProgress(){
  fetch(window.location.href, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') // Add CSRF token if needed
      }
  })
  .then(response => response.json())
  .then(data => {
      console.log(data);
  })
  .catch(error => {
      console.error('Error:', error);
  });
}
 
 