var w_location = window.location.pathname;
if(w_location.includes('analytics'))
{
let token = $('meta[name="csrf-token"]').attr('content');

$.ajax({
    type: 'GET',
    url: '/doelenchart',
    data: {},
    dataType: 'json',
    success: function(response){
        // console.log(response);

        //Voor elk doel een loop doen
        response[0].forEach((doelgroep, i) => {
            // console.log(doelgroep);
          //temp arrays voor push
          let data_array = [];

          let days = response[1][i];
          let c_days = response[2][i];
          let deadline = doelgroep.deadline;
          let streefwaardes = response[4];

          doelgroep.doelwaarde.forEach((d_waarde, index) => {
            let sf_waarde = streefwaardes[i][index];

            //Tweede lijn///////////////////////////////////////////////////////////////
            doel = {label: '', backgroundColor: '#FF00000f', borderColor: '#ff00000e', hidden: false, fill: true, data: [], id: d_waarde.id, tension: 0.1};

            //Ja deze word ook elke keer herhaal
            doel.label = d_waarde.naam + ' Doel';
            
            // let projectie2 = sf_waarde;
            let projectie2 = 0;

            //Streefwaarde per dag * huidige dag voor projectie vandaag
            let projectie2_2 = d_waarde.streefwaarde * c_days;
            // let projectie2_2 = d_waarde.value;


            data_array.push({x: response[3], y:projectie2});

            data_array.push({x: response[5], y:projectie2_2});

            data_array.push({x: deadline, y:d_waarde.goal});


            //array in doel data, array resetten en doel data doorpushen naar chart array
            doel.data = data_array;
            data_array = [];
            doel_data.push(doel);


            //voor elke doelwaarde arrays vullen
            //Elk doel is een dataset
            let doel = {label: '', backgroundColor: '#D0A84Fbf', borderColor: '#D0A84F', hidden: false ,fill: true, data: [], id: d_waarde.id, tension: 0.1};
      
            //Ja deze word ook elke keer herhaal
            doel.label = d_waarde.naam;

            //Eerste lijn Huidig + projectie met huidig///////////////////////////////////
            let projectie1_1 = 0;
            data_array.push({x: response[3], y:projectie1_1});

            //array structuur [{x-as, y-as},{x-as, y-as}]
            data_array.push({x: response[5], y:d_waarde.value});
            
            //Lijn weggehaald vanwege wens job
            // let projectie1 = d_waarde.value / c_days * (days);

            // data_array.push({x: deadline, y:projectie1});

            //array in doel data, array resetten en doel data doorpushen naar chart array
            doel.data = data_array;
            data_array = [];
            doel_data.push(doel);
          });

        });


        const myChart = new Chart(
            document.getElementById('doelenTestChart'),
            config);

        let g_value;
        let g_deadline;
        let g_suffix;
        let g_c_day;
        let g_days;

          $('.goal_chart').on('click', function(e){
            $('.goal_chart.active').removeClass('active');

            let goal_id = $(e.currentTarget).attr('data-goal-id');
            
            let dataset = doel_data.filter(d => d.id == goal_id);

            myChart.data.datasets = dataset;

            // console.log(myChart.data);

            myChart.update();

            $(e.currentTarget).addClass('active');

            g_value = $(e.currentTarget).attr('data-value');
            g_deadline = $(e.currentTarget).attr('data-deadline');
            g_suffix = $(e.currentTarget).attr('data-suffix');
            g_c_day = $(e.currentTarget).attr('data-cday');
            g_days = $(e.currentTarget).attr('data-days');

            $('.prediction_input').show();
          })
          
        var input = document.querySelector('.flex_line');

        $(input).on('change', function(e){
          let val = parseInt(e.target.value);

          g_value = parseFloat(g_value);
          g_days = parseInt(g_days);
          g_c_day = parseInt(g_c_day);

          //Elk doel is een dataset
          let doel = {label: '', backgroundColor: '#00ff000f', borderColor: 'green', hidden: false ,fill: true, data: [], tension: 0.1};

          //Ja deze word ook elke keer herhaal
          doel.label = 'Eigen voorspelling';

          //array structuur [{x-as, y-as},{x-as, y-as}]
          // doel.data.push({x: response[3], y:0});

          doel.data.push({x: response[5], y:g_value});
          let projectie3_1 = val / g_c_day; //* g_days;
          console.log(val, g_c_day, val / g_c_day);
          doel.data.push({x: g_deadline, y:projectie3_1});

          myChart.data.datasets.push(doel);

          myChart.update();
        })

    }
});


var labels = [];

var doel_data = [];
  

const data = {
  datasets: doel_data
};

let delayed;
const config = {
    type: 'line',
    data: data,
    options: {// This chart will not respond to mousemove, etc
      animation: {
        onComplete: () => {
          delayed = true;
        },
        delay: (context) => {
          let delay = 500;
          if (context.type === 'data' && context.mode === 'default' && !delayed) {
            delay = context.dataIndex * 300 + context.datasetIndex * 100;
          }
          return delay;
        },
      },     
      }
  };
}



