import Toastr from "toastr";

Toastr.options = {
    "timeOut": "2000",
    "showDuration": "2000"
}

let token = $('meta[name="csrf-token"]').attr('content');


$('.open_media_library').on('click', function(){
    $('.hidden_library').show();
    $('.content-container').addClass('blur');
    $('.elearningtheme_sidebar').addClass('blur');
})
$('.close_hidden_library').on('click', function(){
    $('.hidden_library').hide();
    $('.content-container').removeClass('blur');
    $('.elearningtheme_sidebar').removeClass('blur');
})

$(document).on('click', '.admin_courses .media', function(){
    let id = $(this).attr('data-id');
    let image = $(this).find('img').attr('src');

    $('.media_image_current').attr('src', image);

    $('#media_id').val(id);

    $('.hidden_library').hide();
    $('.content-container').removeClass('blur');
    $('.elearningtheme_sidebar').removeClass('blur');
})


$(document).ready(function (e) {
    
    //show media popup when clicked on .add_media_slide_in
    $(".add_media_slide_in").click(function() {
        $("#elearning_popup_addmedia").show();
    });

    //hide media popup when clicked on .close_elearning_popup_addmedia
    $(".close_elearning_popup_addmedia, .close_the_addmedia_popup").click(function() {
        $("#elearning_popup_addmedia").hide();
        //change the text content of .custom-file-upload to Uploaden...
        $('.upload-text').text('Uploaden...');
    });

    //change the text content of .custom-file-upload to the file name
    $('input[type="file"]').change(function(e) {
        var fileName = e.target.files[0].name;
        $('.upload-text').text(fileName);
    });


    function setProgress(percent, circle, circumference) {
        let offset = circumference - percent / 100 * circumference;
        circle.style.transition = '1s';
        circle.style.stroke = '#CBA85A';
        circle.style.opacity = '1';
        circle.style.strokeDashoffset = offset;
    }

    function setCircleGetCircumference(circle) {
        let radius = circle.r.baseVal.value;
        let circumference = radius * 2 * Math.PI;

        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = circumference;

        return circumference;
    }

    function getCircumference(circle) {
        let radius = circle.r.baseVal.value;
        let circumference = radius * 2 * Math.PI;

        return circumference;
    }


    $("#media_form").on('submit', (function (e) {
        e.preventDefault();
        let data = new FormData(this);

        let template = document.querySelector('#template');
        let route_url = $(this).attr('data-url');
        let base_url = $(this).attr('data-base_url');

        //set upload text to "Uploaden..."
        $('.upload-text').text('Uploaden...');

        $.ajax({
            url: route_url,
            type: "POST",
            data: new FormData(this),
            contentType: false,
            cache: false,
            processData: false,
            beforeSend: function () {  
                $('.media_box').prepend(template.content.cloneNode(true));
                let circle = document.querySelector('.media.new svg > circle');
                let circumference = setCircleGetCircumference(circle);
                setProgress(0, circle, circumference);
            },
            success: function (data) {
                // console.log(data);
                if (data == 'invalid') {
                    // invalid file format.

                    $("#err").html("Invalid File !").fadeIn();
                }
                else if(data.err)
                {
                    Toastr.error(data.err, 'Probleem');
                    document.querySelector('.media.new').remove();
                }
                else {
                    console.log(data.url);
                    // view uploaded file.
                    // $("#preview").html(data).fadeIn();
                    $("#media_form")[0].reset();

                    let circle = document.querySelector('.media.new svg > circle');
                    let circumference = getCircumference(circle);
                    setProgress(100, circle, circumference);
                    setTimeout(function () {
                        $('.media.new').find('img').attr('src', base_url + data.url)
                        $('.media.new').find('img').show();
                        $('.media.new').attr('data-id', data.id)
                        $('.media.new svg').remove();
                        $('.media.new').removeClass('new');
                    }, 1000)
                    window.location.reload();


                }
            },
            error: function (e) {
                $("#err").html(e).fadeIn();
                console.log('error');
            }
        });

    }));



    function delete_me(item)
    {
        $(item).animate({
            opacity: "0"
        }, 1000, function(){
            $(item).remove();
        })
    }

    $(document).on('click', '.admin_courses .media', function(){
        if(!$(this).parent().parent().hasClass('hidden_library'))
        $(this).toggleClass('selected');
    })

    $('.delete_multiple').on('click', function () {
        // let accept = true;
        
        Swal.fire({
            title: 'Mogelijk is deze media verbonden aan een module, weet je het zeker?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4366f2',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuleer',
            confirmButtonText: 'Media verwijderen!',
        }).then((result) => {
            if (result.isConfirmed) {
                let media = [];
                let base_url = $('#media_form').attr('data-base_url');
                let t_media = $(document).find('.media.selected');
    
                $(t_media).each(function () {
                    console.log($(this));
                    media.push($(this).attr('data-id'));
                })
    
                $.ajax({
                    url: base_url + "/delete_multiple_media",
                    type: "POST",
                    data: {"_token":token, 'media': media},
                    beforeSend: function () {
    
                    },
                    success: function (data) {
                        media.forEach(function(e){
                            delete_me($('.media[data-id='+e+']'));
                        })
                    },
                    error: function (data){
                        console.log(data)
                    }
                });
                Swal.fire(
                    'Verwijderd!',
                    'Succesvol verwijderd.',
                    'success'
                )
            }
        })
        
    })

});

