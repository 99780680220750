// Core SortableJS (without default plugins)
import Sortable from 'sortablejs';
let sortColor = '#e1e1e1';

var base_url = $('#base_url').attr('data-base_url');
var w_location2 = window.location.pathname;
if(w_location2.includes('course-edit'))
{
    //Module draggers
    let token = $('meta[name="csrf-token"]').attr('content');

    let module_list = document.querySelector('.module_list');
    let moduleList = Sortable.create(module_list, {
        handle: '.item',
        filter: '.exclude',
        animation: 150,

        //events
        // Element is chosen
        onChoose: function (/**Event*/evt) {
            $(evt.item).css('border', '2px solid' + sortColor);
            $(evt.item).css('border-radius', '10px');
         },
 
         // Element is unchosen
         onUnchoose: function(/**Event*/evt) {
             $(evt.item).css('border', 'none');
         },

        onUpdate: function (/**Event*/evt) {
            // same properties as onEnd
            let modules = $('.module-core');
            let module_order = [];

            let text = '';
            $(modules).each(function(i, v){
                let m = $(v).attr('data-module_id');
                let order = i+1;
                text = text + '' + m + '-' + order + ' ';
            })
            console.log(text);
            $.ajax({
                type: 'POST',
                url: module_list.dataset.update_url,
                data: {"_token":token, "order": text},
                dataType: 'text',
                success: function(response){
                    // console.log(response);
                    // setTimeout(function()
                    // {
                        
                    // }, 1000)
                }
                });
        },

    });

        //Module media / vragenlijst draggers
        let module_part_list = document.querySelector('.the_extra_module_items');
        let modulePartList = Sortable.create(module_part_list, {
            handle: '.item',
            filter: '.exclude',
            animation: 150,
    
            //events
            // Element is chosen
            onChoose: function (/**Event*/evt) {
                $(evt.item).css('border', '2px solid' + sortColor);
                $(evt.item).css('border-radius', '10px');
             },
     
             // Element is unchosen
             onUnchoose: function(/**Event*/evt) {
                 $(evt.item).css('border', 'none');
             },
    
            onUpdate: function (/**Event*/evt) {
                // same properties as onEnd
                let m_parts = $('.m_part');
                let module_order = [];
    
                let text = '';
                $(m_parts).each(function(i, v){
                    let type =  $(v).attr('data-type');
                    let id =  $(v).attr('data-id');
                    
                    let order = i+1;
                    text = text + '' + type + '-' + id + ':' + order + '|' ;
                })
                $.ajax({
                    type: 'POST',
                    url: module_part_list.dataset.update_url,
                    data: {"_token":token, "order": text},
                    dataType: 'text',
                    success: function(response){
                        console.log(response);
                        // setTimeout(function()
                        // {
                            
                        // }, 1000)
                    },
                    error: function(response)
                    {
                        console.log(response);
                    }
                    });
            },
    
        });

    // Vragen draggers
    if(w_location2.includes('vragenlijst'))
    {
        //Module draggers
        let token = $('meta[name="csrf-token"]').attr('content');

        let vragenlijst = document.getElementById('vragen_list');
        let v_list_sortable = Sortable.create(vragenlijst, {

        handle: '.vraag_container',
        animation: 150,

        //events
        // Element is chosen
        onChoose: function (/**Event*/evt) {
           $(evt.item).css('border', '2px solid' + sortColor);
           $(evt.item).css('border-radius', '10px');
        },

        // Element is unchosen
        onUnchoose: function(/**Event*/evt) {
            $(evt.item).css('border', 'none');
        },

        onUpdate: function (/**Event*/evt) {
            // console.log('test');
            // same properties as onEnd
            let vragen = $('.vraag_container');
            let vraag_order = [];

            let text = '';
            $(vragen).each(function(i, v){
                let v_id = $(v).attr('data-vraag_id');
                let order = i+1;
                text = text + '' + v_id + '-' + order + ' ';
            })
            $.ajax({
                type: 'POST',
                url: base_url + '/set-question-order',
                data: {"_token":token, "order": text},
                dataType: 'text',
                success: function(response){
                    // console.log(response);
                    // setTimeout(function()
                    // {
                        
                    // }, 1000)
                }
                });
        },
        })
    }
};