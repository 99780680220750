$(".send_test_feedback").click(function(){
    let token = $('meta[name="csrf-token"]').attr('content');
    let fb = $('.test_feedback').val();
    let user = $('.hidden_username').val();

    $.ajax({
        type: 'POST',
        url: '/test-feedback',
        data: {"_token":token, feedback:fb, username:user},
        dataType: 'json',
        success: function(response){
        }
      });
});

$('.popup_face').on('click', function(){
    $(this).find('img').toggleClass('popup_clicked');
    $('.feedback_popup').toggle();
})


$(document).ready(function(){
            
    $(".open_feedback").click(function(){
        $(".e_feedback").toggle("slide");
    });
    $(".send_test_feedback").click(function(){
        setTimeout(function(){
            $(".e_feedback").toggle("slide").delay(4000);
            $(".open_feedback").toggle("slide").delay(4000);

        }, 1000); 
        function random(max){
            return Math.random() * (max - 0) + 0;
        }
            var c = document.createDocumentFragment();
            for (var i=0; i<100; i++) {
                var styles = 'transform: translate3d(' + (random(500) - 250) + 'px, ' + (random(200) - 150) + 'px, 0) rotate(' + random(360) + 'deg);\
                            background: hsla('+random(360)+',100%,50%,1);\
                            animation: bang 700ms ease-out forwards;\
                            opacity: 0';
                
                var e = document.createElement("i");
                e.style.cssText = styles.toString();
                c.appendChild(e);
            }
            $(this).append(c);
        });

});