// require('./bootstrap');
require('intl-tel-input/build/js/intlTelInput.min.js');
require('intl-tel-input/build/js/utils.js');
const { round, unset, defaultsDeep, partial } = require("lodash");

const event = new Date(Date.now());
const options = {weekday: 'long'};
const options2 = {day: 'numeric', month: 'long', year:'numeric'};

let today = event.toLocaleDateString('nl-NL', options) + ' | ' + event.toLocaleDateString('nl-NL', options2)

//Pad van de huidige page
var w_location = window.location.pathname;


var deleteLinks = document.querySelectorAll('.delete');

for (var i = 0; i < deleteLinks.length; i++) {
    deleteLinks[i].addEventListener('click', function(event) {
        event.preventDefault();

        Swal.fire({
            title: this.getAttribute('data-confirm'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff0000',
            cancelButtonColor: '#808080',
            cancelButtonText: 'Annuleer',
            confirmButtonText: 'Verwijderen!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = this.getAttribute('href');
                
                Swal.fire(
                    'Verwijderd!',
                    'Succesvol verwijderd.',
                    'success'
                );
            }
        });
    });
}

const checkbox = document.querySelector('.switch_box');
const switch_text = document.querySelector('.switcher_inner p');

$('.switcher_inner a').on( "mouseenter", function(){
    checkbox.checked = !checkbox.checked;
}).on( "mouseleave", function(){
    if(checkbox.dataset.admin == 1)
    {
        checkbox.checked = true;
    }
    else
    {
        checkbox.checked = false;
    }
});

$('.switch_box').on('click', function(e){
    console.log('hi')
    window.location.href = $(e.currentTarget).attr('href');
})

$('.today').text(today);

$('.feedback_day').each(function(e){
    console.log(this);
    const event = new Date(this.innerHTML);
    const options = {weekday: 'long'};
    const options2 = {day: 'numeric', month: 'long', year:'numeric'};

    let today = event.toLocaleDateString('nl-NL', options) + ' | ' + event.toLocaleDateString('nl-NL', options2)

$(this).text(today);
})

$('.goal_add_group').on('click', function(){
    $('.goal_add_sub.active').removeClass('active');
    $('.goal_add_group').addClass('active');
    $('.goals_add_group').show();
    $('.goals_add_goal').hide();
})

$('.goal_add_sub').on('click', function(){
    $('.goal_add_group.active').removeClass('active');
    $('.goal_add_sub').addClass('active');
    $('.goals_add_group').hide();
    $('.goals_add_goal').show();
})

$('.close_all').on('click', function(e){
    let text = e.currentTarget.innerHTML == 'Close all' ? 'Open all' : 'Close all';
    this.innerHTML = text;

    $('.module-parts').each(function(){
        if(text == 'Close all')
        {
            $(this).show();
            $(this).prev().find('.close_single').attr('data-dir', 'down').find('svg').css('transform', 'rotate(180deg)');
        }
        else
        {
            $(this).hide();
            $(this).prev().find('.close_single').attr('data-dir', 'up').find('svg').css('transform', 'rotate(0deg)');
        }
    })

})

$('.vraag_input').on('keyup', function(e){
    if(this.value.includes('-->'))
    {
        let text = this.value;
        console.log(text)

        let replaced = text.replace('-->', '->');
        this.value = replaced;

    }
})

$('.close_single').on('click', function(e){
    // this.innerHTML = e.currentTarget.innerHTML == 'Close all' ? 'Open all' : 'Close all';
    $(this).parent().next().toggle(300, 'linear');
    if($(this).attr('data-dir') == 'up')
    {
        $(this).find('svg').css('transform', 'rotate(180deg)')
        $(this).attr('data-dir', 'down');
    }
    else if($(this).attr('data-dir') == 'down')
    {
        $(this).find('svg').css('transform', 'rotate(0deg)')
        $(this).attr('data-dir', 'up')
    }
})

$('.box_file').on('change', function(){
    $(this).parent().next().css('display', 'block')
})

$('.tool_inputs').on('keyup', function(){
    $('.save-changes').css('display', 'block')
})

$('textarea[name=koptekst]').on('keyup', function(e){
    let count = e.currentTarget.value.length;

    $(this).next().text(3000 - count)
})


$('.hamburger_menu').click(function(){
    $(this).toggleClass('open');
    $('.dashboard-menu, .course-menu').slideToggle();
})
var current_part = $(".hoofdstuk.active").find(".first");

//Klikken op volgende binnen een module
$(".next_page").on('click', function(){
    let current_part_short = $(current_part).attr('data-content');

    $(current_part).next().click();
})

//Klikken op volgende binnen een module
$(".prev_page").on('click', function(){
    let current_part_short = $(current_part).attr('data-content');

    $(current_part).prev().click();
})



// Voortgang bar inladen
$('.p_bar_fill').ready(function(){
    $(".p_bar_fill").each(function(){
        let percent = $(this).attr('data-progress')
        $(this).animate({
            'padding-right' : percent
          }, 2500)
    })
})

$('.feedback_clicker').on('click', function(e){
    let id = $(e.currentTarget).attr('data-um_id');
    $('.left_item.active').removeClass('active');
    $(this).addClass('active');

    $('.feedback_right').hide();
    $('.feedback_right[data-um_id='+id+']').show();
})

$('.slide_feedback').on('click', function(){
    let module = $(this).closest(".feedback_right");
    module.find('.slide_container').toggle();
})



//Voor elke vraag in de module een balletje aanmaken
$(function(){
    let step;
    let current;

    $(".vraag1").addClass('active');
    let vragen = $(".vragenlijst .vraag");

    if (vragen.length == 1) {
        $('.prev, .next').hide();
    }

    $(vragen).each(function(i){
        let id = $(this).attr('data-id');
        if(i == 0)
        {   
            $('.next').before('<span class="ball ball'+id+' first activeball"><div class="balltext">' + (i+1) + '</div></span>');
        }
        else if(i == vragen.length-1)
        {
            $('.next').before('<span class="ball ball'+id+' last"><div class="balltext">' + (i+1) + '</div></span>');
        }
        else
        {
            $('.next').before('<span class="ball ball'+id+'"><div class="balltext">' + (i+1) + '</div></span>');
        } 
    })

    //Stappen halen uit onzichtbaar element

    let stappen = $('.steps').text();
    current = $('.percent').text();

    if(current == '')
    {
        current = 0;
    }

    current = parseFloat(current);

    step = 100 / stappen;
    current += parseFloat(step);

    $(".p_bar_2_fill").css({'padding-right': current+'%'})
    
    $(".next_page, .next").on('click', function(){
        let content = $('.active').find('.vraag_input').val();
        if(!$(this).hasClass('disabled'))
        {
            current += parseFloat(step);
            $(".p_bar_2_fill").css({'padding-right': current+'%'})
        }  
    })
    $(".prev, .prev_page:not(.first)").on('click', function(){
        let content = $('.active').find('.vraag_input').val();
        if(!$(this).hasClass('disabled'))
        {
            current -= parseFloat(step);
            $(".p_bar_2_fill").css({'padding-right': current+'%'})
        }
    })
});
 
function checkforContent(direction)
{
    let content = $('.active').find('.vraag_input').val();
    if(!$(this).hasClass('disabled') && content != '' && direction == 'Volgende')
    {
        current += parseFloat(step);
        $(".p_bar_2_fill").css({'padding-right': current+'%'})
    } 
    else if(!$(this).hasClass('disabled') && content != '' && direction == 'Vorige')
    {
        current -= parseFloat(step);
        $(".p_bar_2_fill").css({'padding-right': current+'%'})
    } 
}

//Click trigger
$(document).on('click', '.question_swap', function(e){
    let direction = $(e.target).text();

    let first = $('.activeball').hasClass('first');
    let last = $('.activeball').hasClass('last');

    let q = $('.activeball').text();

    if(direction == 'Vorige' && !first)
    {
        slide_question(direction, q)
    }
    else if(direction == 'Volgende' && !last)
    {
        slide_question(direction, q)
    }

    first = $('.activeball').hasClass('first');
    last = $('.activeball').hasClass('last');

    if(first)
    {
        $(".prev").addClass('disabled')
        $('.next').removeClass('disabled');
    }
    else if(last)
    {
        $(".next").addClass('disabled')
        $('.prev').removeClass('disabled');
    }
    else
    {
        $(".prev, .next").removeClass('disabled');
    }
});

//Edit goal functie 
var isEditting = false;

var slider = document.querySelector(".slider");

//slider.behaviour = 'smooth';

$('.slider').on('input', function(){
    $('.edit_value').attr('value', slider.value)
  })



//Next / previous question balletjes
function slide_question(dir, q)
{
    if($('.vraag'+q).text() != '')
    {
        $('ball'+q).addClass("filledBall")
    }
    else
    {
        $('ball'+q).removeClass("filledBall")
    }

    if(dir == 'Volgende')
    {
        let curr = $(".activeball");

        $('.vraag'+q).removeClass('active').hide().next().addClass('active').show();
        $(curr).removeClass('activeball').next().addClass('activeball');
    }
    else if(dir == 'Vorige')
    {
        let curr = $(".activeball");

        $('.vraag'+q).removeClass('active').hide().prev().addClass('active').show();
        $(curr).removeClass('activeball').prev().addClass('activeball');
    }
}
$('.vraag_input').ready(function(){
    $('.vraag_answer').each(function(){
        let id = $(this).attr('id');

        $('.ball'+id).addClass('filledBall');
    })
})

// $('.close_menu').on('click', function(){
//     $('.leeromgeving-nils').toggleClass('closed');
//     if($('.leeromgeving-nils').hasClass('closed'))
//     {
//         $('.leeromgeving-nils').css('grid-template-columns', '0% 100%');
//     }
//     else if(!$('.leeromgeving-nils').hasClass('closed')){
//         $('.leeromgeving-nils').css('grid-template-columns', '25% 75%');
//     }
// })

var hook = false;
  window.onbeforeunload = function() {
    if (hook) {

      return "Did you save your stuff?"
    }
  }

$('.vraag_input').on('keydown', function(){
    hook = true;
})

$('input[type=submit]').on('click', function(){
    hook = false;
})
    

//Date invullen bij Goals
$(document).ready(function() {
    var date = new Date();

    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
 
    var today =   day + '-' + month + '-' + year;       
    $('#theDate').attr('value', today);
});




if(w_location.includes('course-edit') && w_location.includes('introductie') || w_location.includes('course-edit') && w_location.includes('tools') || w_location.includes('course-edit') && w_location.includes('podcast'))
{
    tinymce.init({
        selector: 'textarea#tiny',
        menubar: false,
        plugins: 'link lists searchreplace table visualblocks wordcount',
        toolbar: 'bold italic underline strikethrough | link table a11ycheck | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        setup: function(editor) {
            editor.on('keydown', function(e) {
                
            });
        }
    });
}


if(w_location.includes('mail'))
{
    tinymce.init({
        selector: 'textarea#tiny',
        menubar: true,
        plugins: 'link lists searchreplace table visualblocks wordcount image media textcolor',
        toolbar: 'casechange forecolor backcolor blocks bold italic underline strikethrough | link table a11ycheck | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        setup: function(editor) {
            editor.on('keydown', function(e) {
                
            });
        }
    });
}

if(w_location.includes('blog'))
{
    tinymce.init({
        selector: 'textarea#tiny',
        menubar: true,
        plugins: 'link lists searchreplace table visualblocks wordcount image media textcolor',
        toolbar: 'casechange forecolor backcolor blocks bold italic underline strikethrough | link table a11ycheck | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        setup: function(editor) {
            editor.on('keydown', function(e) {
                
            });
        }
    });
}

if(w_location.includes('messages'))
{
    tinymce.init({
        selector: 'textarea#tiny',
        menubar: true,
        plugins: 'link lists searchreplace table visualblocks wordcount image media textcolor',
        toolbar: 'casechange forecolor backcolor blocks bold italic underline strikethrough | link table a11ycheck | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        setup: function(editor) {
            editor.on('keydown', function(e) {
                
            });
        }
    });
}


